<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid1"
        title="즉시조치"
        :isTitle="true"
        :columns="grid.columns"
        :gridHeight="gridHeight"
        :data="tabParam.imprImmData"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="immlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addImprImm" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="grid2"
        class="q-mt-md"
        title="개선요청"
        :isTitle="true"
        :columns="grid2.columns"
        :gridHeight="gridHeight"
        :data="tabParam.imprData"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :editable="editable"
        @linkClick="imprlinkClick"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addImpr" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup"></div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </q-form>
</template>

<script>
export default {
  name: 'council-impr',
  props: {
    tabParam: {
      type: Object,
      default: () => ({
        councilDate: '',
        councilContents: '',
        councilTitle: '',
        councilId: '',
        imprData: [],
        imprImmData: [],
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
        ],
        height: '300px'
      },
      grid2: {
        columns: [
          {
            name: 'actionDeptName',
            field: 'actionDeptName',
            label: '조치부서',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'ibmTitle',
            field: 'ibmTitle',
            label: '제목',
            align: 'left',
            style: 'width:300px',
            sortable: false,
            type: 'link',
          },
        ],
        height: '300px'
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        closeCallback: null,
      },
      colorItems: [],
      requestImprRow: null,
      imprData: null,
      deleteUrl: '',
      searchUrl: '',
      isComplete: false,
      isSave: false,
      isSave2: false,
    };
  },
  computed: {
    gridHeight() {
      let height = 150;
      let _margin = 300;
      if (!this.disabled) {
        _margin += 40
      }
      height = this.contentHeight - _margin
      if (height < 150) {
        height = 150
      }
      return String(height) + 'px';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    addImprImm() {
      this.popupOptions.title = '즉시조치';
      this.popupOptions.param = {
        requestContents: this.tabParam.councilContents,
        relationTableKey: this.tabParam.councilId,
        ibmTaskTypeCd: 'ITT0000080',
        ibmTaskUnderTypeCd: 'ITTU000135',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      // this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    addImpr() {
      this.popupOptions.title = '개선요청';
      this.popupOptions.param = {
        requestContents: this.tabParam.councilContents,
        relationTableKey: this.tabParam.councilId,
        ibmTaskTypeCd: 'ITT0000080',
        ibmTaskUnderTypeCd: 'ITTU000135',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      // this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$emit('getDetail')
    },
    /* eslint-disable no-unused-vars */
    immlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '즉시조치 상세';
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      // this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImmDetailPopup;
    },
    closeImmDetailPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['grid1'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail')
    },
    imprlinkClick(row, col, index) {
      this.imprData = row;
      this.popupOptions.title = '개선요청 상세';
      this.popupOptions.param = {
        sopImprovementId: row.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '99%';
      this.popupOptions.visible = true;
      // this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closeImprDetailPopup;
    },
    closeImprDetailPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        this.$_.extend(this.imprData, result.col1)
        this.$refs['grid2'].$refs['compo-table'].resetVirtualScroll();
      }
      this.$emit('getDetail')
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
